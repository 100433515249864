@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply scroll-smooth;
    }
}